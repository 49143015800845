import Vue from 'vue'
import VueRouter from 'vue-router'
import Programs from '../components/Programs.vue'
import Home from '../components/Home.vue'
import Team from '../components/Team.vue'
import BlogPost from '../components/BlogPost'
import Blog from '../components/Blog'
import Contact from '../components/Contact'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/blog/:id',
    name: 'BlogPost',
    component: BlogPost
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => {
    window.scrollTo(0, 0);
  }
})

export default router
