<template>
  <div id="app">
    <div :class="(loading) ? 'headload' : 'd-none'">
      <img src="assets/images/Eclipse-1s-200px(1).gif">
    </div>
    <div :class="(loading) ? 'd-none' : ''">
      <MobileHeader />
      <WebHeader />
      <router-view class="main-content"/>
      <Footer />
    </div>
  </div>
</template>
<script>
import WebHeader from './components/common/WebHeader';
import Footer from './components/common/Footer';
import MobileHeader from './components/common/MobileHeader';
export default {
  components:{
    WebHeader,
    Footer,
    MobileHeader,
   
  },
  data(){
    return{
       loading:true
    }
  },
  methods:{
    
  },
   created(){
    
    Fire.$on('navigate',  () => {
      this.loading = true;
      setTimeout(()=> { this.loading = false }, 2000);
    })

  },
  mounted(){
  }
}
</script>
<style lang="css">
  .headload{
    width:100vw;height:100vh;display: flex;justify-content: center;align-items: center;
  }
</style>
