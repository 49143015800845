<template>
    <div class="mobiletopmargin">
        <div class="container-fluid third-container p-md-0">
            <div class="tintlayer">
                <div class="container mobilepadding py-4">
                    <div class="row">
                        <h1 class="fw-bold text-center text-white mt-md-5 p-0">Our Board Members</h1>
                        <p class="p-0 text-white text-center mt-4">Kashmir Education Initiative Governing Board comprises of eminent development, business, finance and marketing professionals. They volunteer their time and expertise to help KEI achieve its vision of a world in which all children realise their full potential.</p>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="container-fluid my-5">
            <div class="container">
                <div class="row d-flex flex-column flex-md-row justify-content-between team align-items-center">
                    <div class="w-22 d-flex flex-column align-items-center justify-content-center mb-4 py-3 wobble-hor-bottom"
                        v-for="(member, index) in members" :key="index">
                        <div class="img-holder">
                            <img v-if="member.image !== null" :src="`${apiURL}${member.image.url}`" width="100px" height="100px">
                            <img v-else src="assets/images/nial.jpg" width="100px" height="100px">
                        </div>
                        <h5 class="text-center fw-bold p-0 mt-3 mb-3">{{member.name}}</h5>
                        <!-- <i class="text-center my-2 fs-7" >{{member.profession}}</i> -->
                        <div>
                            <a :href="'mailto:'+member.email" class="btn btn-sm btn-primary rounded-0">Click To Mail</a>
                            <a :href="'tel:'+member.mobile" class="btn btn-sm btn-success rounded-0">Click To Call</a>
                        </div>
                        <p class="p-0 mb-0 text-center mt-4 fs-7">{{member.about}}</p>
                        <div class="d-flex align-items-center justify-content-between w-50 mt-4 mb-4">
                            <a :href="member.facebook"><i class="fab fa-facebook-f icon-color fs-4"></i></a>
                            <a :href="member.twitter"><i class="fab fa-twitter icon-color fs-4"></i></a>
                            <a :href="member.instagram"><i class="fab fa-instagram icon-color fs-4"></i></a>
                        </div>
                    </div>
                    <div class="w-22copy d-flex flex-column align-items-center justify-content-center mb-4 py-3 wobble-hor-bottom ">
                        
                    </div>
                </div>

                <div class="modal-body mt-5">
                        <div class="text-center">
                            <h1 class="fw-bolder lh-1 m-0">Legal Compliance</h1>
                            <p>Know more about Kei</p>
                        </div>
                        
                        <div class="row justify-content-center px-4 align-items-center">
                            <div class="col-md-4 legalimg">
                                <img src="assets/images/logo_kei.png" class="puff-in-center img-fluid">  
                            </div>
                            <div class="col-md-8 legal">
                                <h3 class="fw-bold mb-4">Kashmir Education Initiative meets all the audit, income tax  and  formalities/ requirements that are required by the government</h3>
                                <ul class="fs-7">
                                    <li><b>TAN Number :</b> AMRK12889F</li>
                                    <li><b> PAN Number :</b>  AABAK3524D</li>
                                    <li>Registered as a Society with Registrar of Societies, Govt of Jammu & Kashmir vide  Registration NO: 5822-S dated 10.11.2009) </li>
                                    <li>Tax exemption status under (80-G: No.CIT(E)/Chd/80G/2015-16/3664, dated 02-09-2015).</li>
                                </ul>
                            </div>
                            
                        </div>
                        <div>
                            
                            
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return {
            apiURL,
            members:[]
        }
    },
    methods:{
        getmembers(){
            axios.get(`${apiURL}/members`).then((data) => {
                this.members = data.data
            }).catch()
        }
    },
    mounted(){
        Fire.$emit('navigate');
        this.getmembers();
    }
}
</script>
<style scoped>
    .ul-height{
            height: 100%;
        }
        .first-container-fluid{
            background-color: black;
        }
        .second-container-fluid{
            background: #4a4a4a;
        }
        .donate-now{
            background-color: #1baa79;
            font-size: 15px;
            font-weight: 500;
            border-radius: 0px;
        }
        .nav-icon{
            color: #1baa79;
        }
        .home-icon{
            color: #1baa79;
        }
        .third-container{
            background-image: url("../../public/assets/images/CVh3jzPVAAAuM6-.jpg");
            background-repeat: no-repeat;
            width: 100%;
            background-size:cover;
        }
        .img-holder{
            height: 100px;
            width: 100px;
            border-radius: 5rem;
        }
        .img-holder > img{
            border-radius:5rem;
        }
        .icon-color{
            color: #1baa79;
        }
        .w-22copy{
            width: 23%;
            
        }
        .w-22{
            border: 1px solid #e9e4e4;
            border-radius: 12px;
            width: 23%;
            background-color: whitesmoke;
        }
       .leaf{
            width: 40px;
        }
       .p-footer{
            margin-top: 100px;
        }
       .div-img-footer{
            margin-top: 90px;
        }
        .div-footer{
            margin-top: 120px;
            margin-bottom: 120px;
        }
       .footer{
            background: #4a4a4a;
        }
</style>