<template>
    <div class="mobiletopmargin">
        <div class="container-fluid third-container-fluid p-md-0">
            <div class="tintlayer">
                <div class="container">
                    <div class="row w-75 div-in-third-container">
                            <h1 class="text-center text-white h1-in-third-container p-0">Our Blogs</h1>
                            <!-- <h2 class="text-center text-white mt-1 p-0 mb-0">THEY NEED TO SURVIVE</h2> -->
                            <p class="mb-5 p-0 text-white fw-light small text-center mt-0">Home >> <span class="fw-bold">Blogs</span></p>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="row mt-5">
            <h2 class="text-center fw-normal text-secondary mb-0">Our Recent,<span class="span-h2 text-dark"> Blog Posts!</span></h2>
            <hr class="p-1 h-row-3">
        </div>
        <div class="container-fluid fifth-container-fluid mt-5">
            <div class="container">
                <div class="row d-flex">
                    <div class="col-md-3" v-for="(blog, index) in blogs" :key="index">
                        <div class="div-fifth-container p-0">
                            <img :src="`${apiURL}${blog.image.url}`" class="img-fifth-container">
                            <div class="content px-4 mt-3">
                                 <span class="small date align-self-end float-right">{{ moment(blog.created_at).format('LL') }}</span>
                                 <h5 class="span-h2 mt-0 mb-0">{{ blog.title.substring(0,40) }} <span v-if="blog.title.length > 40">...</span></h5>
                                 <p class="mb-0 mt-3 text-secondary fs-14 fw-light" v-html="blog.body.substring(0,180)"></p>
                                 <router-link :to="`/blog/${blog.id}`" class="btn btn-sm fs-14 donate-now text-white my-4 fw-normal">Read More</router-link>
                            </div>
                           
                        </div>
                    </div>

                                    
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';

export default {
    data() {
        return {
            blogs: [],
            apiURL,
            moment
        }
    },
    mounted() {
        Fire.$emit('navigate');  
        this.init();
        
    },
    created(){
                 
    },
    methods: {
        async init() {
            await axios.get(`${this.apiURL}/posts`).then((data)=> {
                this.blogs = data.data;
            }).catch(()=> {

            });
        }
    }

}
</script>
<style scoped>
    .date{
        font-size: 12px;
        color: #b5b4b4;
    }
    .ul-height{
        height: 100%;
    }
    .first-container-fluid{
        background-color: black;
    }
    .second-container-fluid{
        background: #4a4a4a;
    }
    .donate-now{
        background-color: #1baa79;
        font-size: 15px;
        font-weight: 500;
        border-radius: 0px;
    }
    .nav-icon{
        color: #1baa79;
    }
    .third-container-fluid{
        background-image: url("../../public/assets/images/CVh3jzPVAAAuM6-.jpg");
        background-repeat: no-repeat;
        width: 100%;
        background-size:cover;
    }
    .h1-in-third-container{
        font-weight: 900;
        margin-top: 70px;
    }
    .div-in-third-container{
        margin-left: auto;
        margin-right: auto;
    }
    .donate-monthly{
        background-color: #1baa79;
        font-size: 15px;
        font-weight: 500;
        border-radius: 0px;
        width: 170px;
        margin-left: auto;
        margin-right: auto;
    }
    .img-in-fourth-container{
        height: 500px;
        padding: 30px;
    }
    .h1-in-fourth-container{
        font-weight: 900;
    }
    .h-row-3{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
    .education{
        background-color: #1baa79;
        font-size: 15px;
        font-weight: 500;
        border-radius: 0px;
        width: 140px;
    }
    .col-7{
        padding: 85px 0px 0px 20px;
    }
    .span-h2{
        font-weight: 900;
        color: #1baa79;
    }
    .div-fifth-container{
        border: 1px solid rgb(221, 219, 219);
        border-radius: 10px;
        border: none;
        box-shadow: 1px 1px 6px rgba(0,0,0,0.17);
        margin-bottom: 40px;
    }
    .img-fifth-container{
        width: 100%;
        height: 200px;
        border-radius: 10px 10px 0px 0px;
    }
    .leaf{
        width: 40px;
    }
    .p-footer{
        margin-top: 100px;
    }
    .div-img-footer{
        margin-top: 90px;
    }
    .div-footer{
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .footer{
        background: #4a4a4a;
    }
    .donation-img{
        width: 60px;
    }
    .h-row-4{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        margin-left: auto;
        /* margin-right: auto; */
        margin-top: 10px;
    }
    .h-row-5{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        /* margin-left: auto; */
        margin-right: auto;
        margin-top: 10px;
    }
    .h-row-6{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        margin-left: 85px;
        margin-right: auto;
        margin-top: 10px;
    }
   .img-donation2{
        justify-content: center;
        background-color: #1baa79;
        width: 90px;
        height: 60px;
        border-radius: 4rem;
        display: flex;
     }
    .donation-box{
        padding: 15px;
    }
    .donation2-img{
        height: 45px;
        margin-top: 6px;
    }
    .donation-container{
        padding: 105px 0 90px 30px;
    }
</style>