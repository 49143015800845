<template>
    <div>
         <!-- Header-->
        <header class="bg-success bg-gradient text-white py-5">
            <div class="container px-4 text-center">
                <h1 class="fw-bolder">Our Programmes</h1>
                <p class="lead">Kei offers multiple programs in different fields to support students having brilliance but with financial problems</p>
                <a class="btn btn-sm btn-light" href="#about">Start Exploring!</a>
            </div>
        </header>
        <!-- About section-->
        <section id="about">
            <div class="container px-4 py-5">
                <div class="row gx-4 justify-content-center">
                    <div class="col-lg-8">
                        <h2>1.	KEI Undergraduate Scholarship (UGS)</h2>
                        <p class="lead">This scholarship is meant to support students who have qualified the competitive entrance tests in the open merit 
category for MBBS, Engineering and technology, and Agriculture in the institutions in Kashmir region.  </p>
                        <p class="lead fw-bold">Eligibility</p>
                        padding
                        <ul class="fw-light">
                            <li>Advertisement will be issued immediately after the admissions are done through NEET/JEE & Agricultural professional course of the current year</li>
                            <li>The students must have obtained more than 80% marks (aggregate) in the 10th and 12th class board exams. They  must have already obtained admission and completed the admission formalities in one of these institutes.</li>
                            <li>The annual household income is not more than 3 lakh rupees. </li>
                            <li>Depending upon the quality of the students the scholarship is awarded to 20-30 students each year, The merit is drawn based on the marks obtained in the 10th/12th board exams as well as the points obtained in their entrance tests.   </li>
                            <li>Besides scholarship, students are offered career guidance and counseling.  </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- About section-->
        <section id="about" class="bg-light">
            <div class="container px-4 py-5">
                <div class="row gx-4 justify-content-center">
                    <div class="col-lg-8">
                        <h2>2.  KEI Post Graduate Scholarship (PGS): </h2>
                       <p class="lead">The scholarship is awarded to support and mentor students pursuing post graduate programs but at the same time facing financial problems. <br/> 
                       Selection is made on the basis of the three rounds of screening:  <br />
                       The first round of selection is made on the basis of the marks obtained in the 10th, 12th and B.A/B.Sc/B.Com examinations. <br />
                       In the second round, the shortlisted students submit their resume besides application write-up.  <br />
                       In the third and final round, the shortlisted students are invited for an exhaustive interview.  <br />
                       Depending upon the performance in the 3 rounds of selection process 10-20 students are selected for this scholarship. <br />
                       The students receive scholarship for two years of their post graduation, besides mentorship and career counselling.
                       </p>
                        <p class="lead fw-bold">Eligibility</p>
                        <ul class="fw-light">
                            <li>Advertisement will be issued immediately after the admissions are done through Common University Entrance Test (CUET) of the current year</li>
                            <li>The students must have secured first division in all the 10th, 12th and B.A/B.Sc/B.Com examinations.</li>
                            <li>Must have qualified the entrance test conducted for the admission of the respective postgraduate courses in any of the recognized institutes/ universities of Kashmir in the open merit category.</li>
                            <li>Students in the first year of their Post graduation/in the first year of the integrated masters courses are eligible to apply.</li>
                            <li>The annual household income should not be more than 3 lac INR. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- Services section-->
        <!-- About section-->
        <section id="about">
            <div class="container px-4 py-5">
                <div class="row gx-4 justify-content-center">
                    <div class="col-lg-8">
                        <h2>3.	Career Counselling</h2>
                        <p class="lead">
                            The objective of this program is to provide counselling for 10, 10+2 and undergraduate 
                            career choices in the premier institutions in India and abroad with lucrative job 
                            placement opportunities. KEI aims at guiding the eligible candidates into highly paid 
                            careers in STEM, Data science, AI, Design, Languages, Literature, Journalism, Liberal 
                            Arts, Management and many more. KEI, through its counseling sessions aims at to 
                            bridge the gap of the information asymmetry amongst the students in the valley and help 
                            them make informed career choices.  
                        </p>
                        <p class="lead fw-bold">Eligibility</p>
                        <ul class="fw-light">
                            <li>Any Student /Parent keeping in view particular class for which Counselling is held</li>
                            <li>No income sealing ( Open to All) </li>
                            <li>The counselling is free for all. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- About section-->
        <!-- About section-->
        <!-- <section id="about" class="bg-light mb-5">
            <div class="container px-4 py-5">
                <div class="row gx-4 justify-content-center">
                    <div class="col-lg-8">
                        <h2>4.	Mentorship Program </h2>
                        <p class="lead">For KEI its the cornerstone for its intervention and a distinguishing component of its offering--as absence of a role models or an academic guide who could direct them to their goal led KEI to create a one to one Mentorship program. This program is the first of its kind in Kashmir. These highly ambitious students need someone who can direct them towards their goal as well becomes their overall guide, helping them hone their personalities.</p>
                        
                    </div>
                </div>
            </div>
        </section> -->

        <section id="about" class="bg-light mb-5">
            <div class="container px-4 py-5">
                <div class="row gx-4 justify-content-center">
                    <div class="col-lg-8">
                        <h2>Important Links </h2>
                        <ul class="fw-light">
                            <li><a href="https://nsp.gov.in">National Scholarship Portal</a></li>
                            <li><a href="https://scholarships.gov.in/">Scholarship Portal</a></li>
                            <li><a href="https://www.aicte-jk-scholarship-gov.in/">JK Scholarship Portal by AICTE</a></li>
                            <li><a href="https://www.kashmirei.org/">KEI USA</a></li>
                            <li><a href="https://www.ugc.ac.in/ugc_schemes">UGC Schemes/ Scholarship</a></li>


                        </ul>
                        
                    </div>
                </div>
            </div>
        </section>
        

    </div>
</template>
<script>
export default {
    mounted(){
        Fire.$emit('navigate');
    }
}
</script>
<style lang="css" scoped>
    .lead{
        font-size:16px !important;
    }
    @media screen and (max-width: 550px){
        header {
            margin-top: -25px !important;
        }
    }
</style>