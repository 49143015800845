<template>
    <div>
        <div class="modal fade" id="Legal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
        tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <h1 class="fw-bolder lh-1 m-0">Legal Compliance</h1>
                            <p>Know more about Kei</p>
                        </div>
                        
                        <div class="row justify-content-center px-4 align-items-center">
                            <div class="col-md-4 legalimg">
                                <img src="assets/images/logo_kei.png" class="puff-in-center img-fluid">  
                            </div>
                            <div class="col-md-8 legal">
                                <h2 >Kashmir Education Initiative meets all the audit, income tax  and  formalities/ requirements that are required by the government</h2>
                                <ul class="fs-7">
                                    <li><b>TAN Number :</b> AMRK12889F</li>
                                    <li><b> PAN Number :</b>  AABAK3524D</li>
                                    <li>Registered as a Society with Registrar of Societies, Govt of Jammu & Kashmir vide  Registration NO: 5822-S dated 10.11.2009) </li>
                                    <li>Tax exemption status under (80-G: No.CIT(E)/Chd/80G/2015-16/3664, dated 02-09-2015).</li>
                                </ul>
                            </div>
                            
                        </div>
                        <div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return {
            modal:() =>{}
        }
    },
   
    mounted() {
        this.modal = new bootstrap.Modal(document.querySelector('#Legal'));
    },
}
</script>

<style lang="css" scoped>
.legal{
    padding: 20px 30px;
    border: 1px solid #ededed;
    border-radius: 15px;
    margin-bottom: 20px;
    color:white;
    background: #1baa79;
}
.legal > h2 {
    font-size: 1.2rem;
    color: white;
    font-style: italic;
    margin-bottom: 36px;
    font-weight: 600;
}
.modal-body{
    padding:3rem 1rem !important;
}

@media (max-width:560px){
    .legalimg > img{
        width: 140px;
        margin-bottom:20px;
    }
    .legalimg{
        display:flex;
        justify-content: center;
    }
}
    
</style>