<template>
    <!-- Mobile Notification -->
    <div class="row d-md-none">
        <div class="w-50 message-box-new">
            <!-- <p class="p-0 text-white fw-light">KEI supports financially weak but academically brilliant students during their studies. We begin with 9th grade students selected through a defined transparent process and guide them through various programs to perform to the best of their abilities and pursue their higher education confidently.</p>
                <div>
                <button class="btn btn-lg text-white donate-now" data-bs-toggle="modal" href="#Donate1" role="button">DONATE NOW</button>
                </div> -->
            <span class="badge bg-danger heartbeat" v-if="data.latest">Latest</span>
            <p class="text-white fw-bold">{{ data.title }}</p>
            <p class="p-0 text-white fw-light">{{ data.detail }}</p>
            <div>
                <!--<div> -->
                <a class="btn btn-lg text-white donate-now" v-if="data.link" :href="data.link">{{ data.linkTitle }}</a>
                <!--  <a class="btn btn-lg text-white donate-now ms-3" href="notifications/UGS_Adv_2022.pdf">DOWNLOAD PDF</a>
                </div>-->
                <p class="fs-8 text-white mt-2"><i class="text-danger" v-if="data.notice">**</i>{{ data.notice }}</p>
            </div>
        </div>
    </div>
    <!-- Mobile Notification -->
</template>


<script>
export default {
    name: 'NotificationMobile',
    props: [ 'data' ]
}
</script>