<template>
    <div class="mobiletopmargin">
        <div class="container-fluid third-container p-md-0">
            <div class="tintlayer">
                <div class="container">
                    <div class="row">
                        <h1 class="fw-bold text-center text-white mt-5 p-0 mb-5">Contact Us</h1>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="container mt-5 mobilepadding">
            <div class="row">
                <div class="col-md-8">
                    <div class="well well-sm">
                        <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-3">
                                    <label for="name" class="mb-1">
                                        Name</label>
                                    <input type="text" v-model="formData.name" class="form-control" id="name" placeholder="Enter name" required="required" />
                                </div>
                                <div class="form-group mb-3">
                                    <label for="email" class="mb-1">
                                        Email Address</label>
                                    <div class="input-group">
                                        <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                                        </span>
                                        <input type="email" v-model="formData.email" class="form-control" id="email" placeholder="Enter email" required="required" /></div>
                                </div>
                                <div class="form-group mb-3">
                                    <label for="subject" class="mb-1">
                                        Subject</label>
                                    <select id="subject" v-model="formData.subject" name="subject" class="form-control" required="required">
                                        <option value="na" selected="">Choose One:</option>
                                        <option value="service">General Customer Service</option>
                                        <option value="suggestions">Suggestions</option>
                                        <option value="scholarship">Scholarship</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group mb-3">
                                    <label for="name" class="mb-1">
                                        Message</label>
                                    <textarea name="message" v-model="formData.message" id="message" class="form-control" rows="9" cols="25" required="required"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" @click.prevent="sendMessage" class="btn btn-success pull-right" id="btnContactUs">
                                    Send Message</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-4 mt-5 mb-5">
                    <form>
                    <legend class="fs-1 fw-bolder"><span class="glyphicon glyphicon-globe"></span> Our office</legend>
                    <address>
                        <strong>Kashmir Education Initiative</strong><br>
                        Dr.Ali Jan's House, <br>
                        Near Khalsa School, Magarmal Bagh<br>
                        Srinagar-190001<br>
                        <abbr title="Phone">
                            P:</abbr>
                        (0194) - 2458153
                    </address>
                    <address>
                        <strong>Mail us</strong><br>
                        <a href="mailto:info@keikashmir.org">click to mail</a>
                    </address>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            apiURL,
            formData: {
                
            }
        }
    },  
    mounted() {
        Fire.$emit('navigate');
    },
    methods: {
        sendMessage() {
            axios.post(`${this.apiURL}/queries`, this.formData).then(()=> {
                Swal.fire('Success', "Your Query Form Submitted Successfully<br> You Will be Contacted Shortly", 'success');
                this.formData = {};
            }).catch((err)=>{
                Swal.fire('Error!', "All Fields Are Required", 'error');
                return;
            });
        }
    }
}
</script>

<style scoped>
.third-container{
    background-image: url("../../public/assets/images/CVh3jzPVAAAuM6-.jpg");
    background-repeat: no-repeat;
    width: 100%;
    background-size:cover;
}
</style>