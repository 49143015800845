<template>
    <div>
        <div class="container-fluid bg-dark">
            <div class="container">
                <div class="row mobilepadding">
                    <!-- <p class="mb-0 p-0 fw-bold fs-3 lh-1 text-white p-footer">The values that holds us true<br>and to account</p> -->
                    <div class="row d-flex justify-content-between div-img-footer">
                        <div class="col-md-6 mb-4">
                            <div class="d-flex align-items-center p-0">
                                <img src="assets/images/Group 1274.png" class="leaf">
                                <p class="mb-0 p-0 ms-3 text-white fs-3">Vision</p>
                            </div>
                            <p class="mb-0 p-0 text-white fw-light mt-3">Building a robust community of citizens by creating conditions for nurturing 
                                talent in the pursuit of excellence amongst the students in Kashmir and to foster a 
                                conducive environment for producing future leaders in all areas of human 
                                endeavour. 
                            </p>
                        </div>
                        <div class="col-md-6">
                            <div class="d-flex align-items-center p-0">
                                <img src="assets/images/Group 1274.png" class="leaf">
                                <p class="mb-0 p-0 ms-3 text-white fs-3">Mission</p>
                            </div>
                            <p class="mb-0 p-0 text-white fw-light mt-3">
                                To work with the student community, parents, donors, advisors, 
                                counselors, philanthropists, academicians and professionals towards creating 
                                working conditions for offering mentorship, guidance and career counseling 
                                supported by financial assistance to the student community in Kashmir. 
                            </p>
                        </div>
                        <!-- <div class="w-25">
                            <div class="d-flex align-items-center p-0">
                                <img src="assets/images/Group 1274.png" class="leaf">
                                <p class="mb-0 p-0 ms-3 text-white fs-3">Unique</p>
                            </div>
                            <p class="mb-0 p-0 text-white fw-light mt-3">To help people see their donation as a minor gift as opposed to a major sacrifice</p>
                        </div> -->
                    </div>
                    <div class="row d-flex justify-content-between div-footer">
                        <div class="w-auto">
                            <h2 class="text-white fw-bold mb-4">Important <span class="fw-lighter">Links</span></h2>
                        <ul class="m-0 p-0">
                            <li class="text-white list-unstyled"><a href="https://kashmirei.org" class="text-decoration-none text-white">KEI USA</a></li>
                            <li class="text-white list-unstyled"><a href="https://nsp.gov.in/" class="text-decoration-none text-white">National Scholarship Portal</a></li>
                            <li class="text-white list-unstyled"><a href="https://scholarships.gov.in/" class="text-decoration-none text-white">Scholarship Portal</a></li>
                            <li class="text-white list-unstyled"><a href="https://www.aicte-jk-scholarship-gov.in/" class="text-decoration-none text-white">JK Scholarship Portal by AICTE</a></li>
                            <li class="text-white list-unstyled"><a href="https://www.ugc.ac.in/ugc_schemes" class="text-decoration-none text-white">UGC Schemes/ Scholarship</a></li>
                        </ul>
                        </div>
                        <div class="w-auto">
                            <h2 class="text-white fw-bold mb-4">Contact <span class="fw-lighter">Us</span></h2>
                        <ul class="m-0 p-0">
                            <li class="text-white list-unstyled mb-2"><i class="fas fa-map-marker-alt me-2"></i> Magarmal Bagh , Srinagar-190001</li>
                            <li class="text-white list-unstyled mb-2"><i class="fas fa-envelope me-2"></i><a href="mailto:scholarships@keikashmir.org" class="text-white text-decoration-none">scholarships@keikashmir.org</a></li>
                            <li class="text-white list-unstyled mb-2"><i class="fas fa-mobile me-2"></i>+0194-2458153</li>
                            
                        </ul>
                        </div>
                       
                        <div class="w-40">
                            <h2 class="text-white fw-bold mb-4">About <span class="fw-lighter">Us</span></h2>
                            <p class="fw-lighter fs-14 text-white">
                                KEI is a non-political and non-profit organisation focusing on supporting financially weak but academically brilliant students during their studies
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid footer">
            <div class="container">
                <div class="row">
                    <p class="text-white text-center m-0 p-2 small">&copy; 2021 keikashmir.org | Designed, developed and maintained by <a href="https://redarc.in" target="_blank" class="text-white" rel="noopener noreferrer">RedArc Technology</a></p>
                </div>
            </div>
        </div>
        <Donate />
        <Volunteer />
        <Legal />
    </div>
</template>
<script>
import Legal from './Legal'
import Donate from './Donate';
import Volunteer from './Volunteer';
export default {
    components:{
        Donate,
        Volunteer,
        Legal
    }
}
</script>
<style scoped>
    .sixth-container-fluid{
        background: #4a4a4a;
        margin-top: 150px;
    }
    .simran-img-div{
        margin-top: -100px;
    }
    .leaf{
        width: 40px;
    }
    .p-footer{
        margin-top: 100px;
    }
    .div-img-footer{
        margin-top: 90px;
    }
    .div-footer{
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .footer{
        background: #4a4a4a;
    }
    .w-40 { width: 40% ; }
</style>