<template>
    <div>
        <div class="modal fade" id="Volunteer" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
        tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <h1 class="fw-bolder lh-1 m-0">Join Kei</h1>
                            <p>and make a difference</p>
                        </div>
                        
                        <div class="w-100 d-flex justify-content-center">
                            <img src="assets/images/358-3585990_volunteer-hands-with-heart-icon.jpg" class="w-75 puff-in-center">
                        </div>
                        <div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Your Name</label>
                                <input type="text" class="form-control" v-model="form.name" placeholder="Enter Your Name">
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Your Email</label>
                                <input type="email" class="form-control" v-model="form.email" placeholder="Enter Your Email">
                                <div id="emailHelp" class="form-text">This information will be kept secret.</div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Your Mobile Number</label>
                                <input type="text" class="form-control" v-model="form.contact" placeholder="Enter Your Mobile Number">
                                <div id="emailHelp" class="form-text">This information will be kept secret.</div>
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Reason To Join</label>
                                <textarea class="form-control" v-model="form.reason"  value="Enter Your Reason To Join Kei"></textarea>
                            </div>
                            <button class="btn btn-md btn-success w-100 my-3" @click="submit()">Register</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
    data(){
        return {
            apiURL,
            form:{

            },
            modal:() =>{}
        }
    },
    methods:{
        submit(){
            axios.post(`${apiURL}/volunteers`,this.form).then(
                () => {
                    this.form = {};
                    Swal.fire(
                        'Success',
                        'Your Request Has been Submitted',
                        'success'
                    )
                    this.modal.hide();
                }
            ).catch( (error) => {
                console.log(error)
                Swal.fire(
                    'Error',
                    'All Fields Are Required',
                    'error'
                )
            })
        }
    },
    mounted() {
        this.modal = new bootstrap.Modal(document.querySelector('#Volunteer'));
    },
}
</script>