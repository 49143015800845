<template>
    <div>
        <div class="modal fade" id="Donate1" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
        tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <h1 class="fw-bolder lh-1 m-0">Your Donation</h1>
                            <p>can make a difference</p>
                        </div>
                        
                        <div class="d-flex flex-column justify-content-evenly mt-4" v-if="!online">
                             <div class="w-100 d-flex justify-content-center">
                                <img src="assets/images/donate.png" class="w-75 puff-in-center">
                            </div>
                            <div class="d-flex mt-3 justify-content-center">
                                <div class="d-flex flex-column me-2">
                                     <button class="btn btn-md btn-outline-success mb-2" data-bs-toggle="modal" href="#Donate2" data-bs-dismiss="modal" >Direct Transfer (National)</button>
                                    <button class="btn btn-md btn-outline-success">Direct Transfer (International)</button>
                                </div>
                               <button class="btn btn-lg btn-success" @click="online = true">Donate Online</button>
                            </div>
                            
                        </div>
                        <div class="d-flex flex-column justify-content-evenly align-items-center mt-4" v-else>
                            <div class="paymentinput mb-3 fs-7">
                                <label for="amount">Enter Name</label>
                                <input type="text" v-model="form.name" placeholder="Enter your name">
                            </div>

                            <div class="paymentinput mb-3 fs-7">
                                <label for="amount">Enter Email</label>
                                <input type="text" v-model="form.email" placeholder="Enter your email">
                            </div>

                            <div class="paymentinput mb-3 fs-7">
                                <label for="amount">Enter Phone</label>
                                <input type="text" v-model="form.phone" placeholder="Enter your phone number">
                            </div>

                            <div class="paymentinput mb-3 fs-7">
                                <label for="amount">Enter Amount</label>
                                <input type="text" v-model="form.amount" placeholder="Enter amount to donate">
                            </div>

                            <div class="my-3">
                                <button class="btn btn-md btn-success" @click="submit">Donate Online</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="Donate2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">
                            <h1 class="fw-bolder lh-1 m-0">We Thank You</h1>
                            <p>For Your Donation</p>
                        </div>
                        
                        <div class="w-100 d-flex justify-content-center">
                            <img src="assets/images/jkb.png" class="w-75 puff-in-center">
                        </div>
                         <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Bank Name</label>
                            <input type="email" class="form-control"  value="The J&K bank Limited, Hazratbal">
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Account Name</label>
                            <input type="email" class="form-control"  value="Kashmir Education Initiative">
                        </div>
                       <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Account Type</label>
                            <input type="email" class="form-control"  value="Current Account">
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Account No</label>
                            <input type="email" class="form-control"  value="0007010100002718">
                            <div id="emailHelp" class="form-text">Kei Official Account.</div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">IFSC code</label>
                            <input type="email" class="form-control"  value="JAKA0NASEEM">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from 'sweetalert2';
export default {
    data(){
        return {
            online:false, 
            form:{},
            modal: () => {}
        }
    },
    methods:{
        submit: function(){
                let vm = this;
                // Initiate payment
                var options = {
                    key: "rzp_live_NUy98EtKZZY8zo",
                    amount: (this.form.amount*100),
                    name: 'Kashmir Education Initiative',
                    description: 'Donate for a better world',
                    image: `https://keikashmir.org/assets/images/NEw_KEI_logo_2019_7200.png`,
                    prefill: {
                        name: this.form.name,
                        contact: this.form.phone,
                        email: this.form.email
                    },
                    theme: {color: '#03CEA4'},
                    handler: function(response){
                        // let paymentId = data.data.id;
                        // Confirm the donation
                        vm.online = false;
                        vm.modal.hide();
                        Swal.fire(
                            'Success!',
                            'You have Successfully Made a Donation!',
                            'success'
                        )
                    }
                }
                let r = new Razorpay(options);
                r.open();
            
            
        },
    },
    mounted() {
        this.modal = new bootstrap.Modal(document.querySelector('#Donate1'));
    },
}
</script>

<style lang="css">
    .paymentinput{
        display: flex;
        flex-direction: column;
        border: 1px solid #ebebeb;
        border-radius: 12px;
        padding: 0.3rem 1rem;
        width: 75%;
    }
    .paymentinput > input{
        border:none;
        outline:none;
        color:#838383;

    }
</style>