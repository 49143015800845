import Vue from 'vue'
import App from './App.vue'
import router from './router'
import marked from 'marked';
Vue.config.productionTip = false

Vue.use({
  install () {
    Vue.marked = marked
    Vue.prototype.$marked = marked
  }
})

window.apiURL = "https://backend.keikashmir.org";
let Fire = new Vue();
 
window.Fire = Fire;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
