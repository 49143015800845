<template>
    <div class="mobiletopmargin">
        <div class="container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 mobilepadding">
                        <div class="mt-md-5 mt-4">
                            <p class="mb-0 p-0 text-secondary fw-normal"><i class="fas fa-home home-icon me-2"></i>  Home >> World >> <a href="" class="text-decoration-none charity-link">Charity</a></p>
                        </div>
                        <h1 class="fw-bold mb-0 mt-md-5 mt-4">{{post.title}}</h1>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <p class="p-0 mb-0 fs-8"><i class="fas fa-user text-secondary me-1 fs-8"></i><a href="" class="text-decoration-none fw-normal text-secondary">{{post.users_permissions_user.username}}</a></p>
                            <p class="p-0 mb-0 fs-8"><i class="fas fa-clock text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">{{moment(post.created_at).format('LLL')}}</a></p>
                            <p class="p-0 mb-0 fs-8"><i class="fas fa-hand-holding-usd text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">{{post.category.name}}</a></p>
                            <p class="p-0 mb-0 fs-8"><i class="fas fa-eye text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">{{post.views}} views</a></p>
                        </div>
                        <div class="pt-5">
                            <img src="assets/images/kashmir-school-12002.jpg" class="w-100">
                        </div>
                        <div class="d-flex justify-content-between pt-5">
                            <button class="btn btn-lg facebok-button fw-bold">Share on facebok</button>
                            <button class="btn btn-lg facebok-button fw-bold">Share on facebok</button>
                            <button class="btn btn-lg facebok-button fw-bold">Share on facebok</button>
                        </div>
                        <div class="main-body mt-5" v-html="$marked(post.body)">

                        </div>
                        <!-- <h1 class="mb-0 fw-bold mt-5">Ukraine Charitable organisation</h1>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <p class="p-0 mb-0"><i class="fas fa-user text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">Adina Yousuf</a></p>
                            <p class="p-0 mb-0"><i class="fas fa-clock text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">28,April 2021</a></p>
                            <p class="p-0 mb-0"><i class="fas fa-comment text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">Leave a comment</a></p>
                            <p class="p-0 mb-0"><i class="fas fa-eye text-secondary me-1"></i><a href="" lass="text-decoration-none fw-normal text-secondary">22,000 views</a></p>
                        </div>
                        <div class="row img-text-row mt-2">
                            <div class="col-4">
                                <img src="assets/images/jfncjzla.png" class="girl-img">
                            </div>
                            <div class="col-8">
                                <p class="p-0 mb-0 font-size">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim reprehenderit sunt qui, architecto dolor voluptatem! Quos iure, molestias blanditiis nobis natus, aspernatur, voluptatibus quis ipsam commodi cumque tempore et perferendis.</p>
                                <p class="p-0 mb-0 mt-4 font-size">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim reprehenderit sunt qui, architecto dolor voluptatem! Quos iure!</p>
                            </div>
                        </div> -->
                        <h2 class="mb-0 span-h2 mt-5">Recent <span class="fw-light"> Comments</span></h2>
                        <hr class="p-1 h-row-4">
                        <div v-if="post.comments.length > 0">
                            <div class="row mt-5" v-for="(comment,index) in post.comments" :key="index">
                                <div class="col-3 d-flex justify-content-center align-items-center">
                                    <div class="circle"></div>
                                </div>
                                <div class="col-9">
                                    <h3 class="fw-bold mb-0">{{comment.title}}</h3>
                                    <div class="d-flex justify-content-between align-items-center mt-2">
                                        <p class="p-0 mb-0 fs-8"><i class="fas fa-user text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">Adina Yousuf</a></p>
                                        <p class="p-0 mb-0 fs-8"><i class="fas fa-clock text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">28,April 2021</a></p>
                                        <p class="p-0 mb-0 fs-8"><i class="fas fa-eye text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary">22,000 views</a></p>
                                    </div>
                                    <p class="mb-0 mt-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsum voluptatibus doloribus obcaecati dignissimos alias voluptatum laudantium magnam, nihil minus architecto odio iusto eaque, aliquid culpa</p>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div class="row mt-5 mb-5" v-else>
                            <p>No Comment Yet</p>
                            <i class="fs-8">Be The First one To Comment</i>
                        </div>
                        <h2 class="mb-0 span-h2 mt-4">Leave <span class="fw-light"> a reply</span></h2>
                        <hr class="p-1 h-row-5">
                        <div class="chat mb-5">
                            <p class="mb-0 p-0 font-size">Your email address will not be published.Required fields are marked*</p>
                            <p class="mb-0 p-0 text-secondary mt-1">Comment</p>
                            <textarea rows="7" class="w-100 mt-2 text-area" v-model="form.comment"></textarea>
                            <p class="text-secondary mb-0 p-0">Title<span class="text-danger"> *</span></p>
                            <input type="text" class="input-text" v-model="form.title">
                            <p class="text-secondary mb-0 p-0">Name<span class="text-danger"> *</span></p>
                            <input type="text" class="input-text" v-model="form.name">
                            <p class="text-secondary mb-0 p-0 mt-3">Email<span class="text-danger"> *</span></p>
                            <input type="text" class="input-text" v-model="form.email">
                            <p class="text-secondary mb-0 p-0 mt-3">Website<span class="text-danger"> *</span></p>
                            <input type="text" class="input-text"><br>
                            <button class="btn btn-md text-white donate-now mt-4" @click="submitcomment()">Post comment</button>
                        </div>
                    </div>
                    <div class=" col-md-4 side-box mobilepadding">
                        <h2 class="mb-0 span-h2 mt-4">Latest <span class="fw-light"> Posts</span></h2>
                        <hr class="p-1 h-row-4 mb-5">
                        <div class="border">
                            <div class="row w-100 d-flex p-0 mt-2" v-for="(single,index) in posts" :key="index">
                                <div class="w-35">
                                    <img src="assets/images/yicjeniu.png" class="img w-100">
                                </div>
                                <div class="w-60">
                                    <div>
                                        <h6 class="fw-bold m-0">{{single.title.substring(0,40) }}<span v-if="single.title.length>40">...</span> </h6>
                                        <div class="d-flex justify-content-between mt-3">
                                            <p class="p-0 mb-0"><i class="fas fa-eye text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary font-size fs-8">22,000 views</a></p>
                                            <p class="p-0 mb-0"><i class="fas fa-clock text-secondary me-1"></i><a href="" class="text-decoration-none fw-normal text-secondary font-size fs-8">28,April 2021</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="mb-0 span-h2 mt-4">Latest <span class="fw-light"> Images</span></h2>
                        <hr class="p-1 h-row-4">
                        <div class="border1">
                            <div class="row d-flex justify-content-around mt-4">
                                <div class="w-30 mb-3" v-for="(image,index) in images" :key="index">
                                    <img :src="`${apiURL}${image.image.url}`" class="w-100">
                                </div>
                            </div>
                            
                        </div>
                        <h2 class="mb-0 span-h2 mt-4">Follow <span class="fw-light"> us</span></h2>
                        <hr class="p-1 h-row-4">
                        <div class="d-flex mt-4 mb-5">
                            <a href="https://twitter.com/kashmireiorg" class="wobble-hor-bottom circle1 d-flex justify-content-center align-items-center me-3 text-decoration-none"><i class="fab fa-twitter fs-1 color"></i></a>
                            <a href="" class="wobble-hor-bottom circle1 d-flex justify-content-center align-items-center me-3 text-decoration-none"><i class="fab fa-instagram fs-1 color "></i></a>
                            <a href="https://www.youtube.com/channel/UCavw-yuLmLi1VefwpWw18LA" class="wobble-hor-bottom circle1 d-flex justify-content-center align-items-center me-3 text-decoration-none"><i class="fab fa-youtube fs-1 color "></i></a>
                            <a href="https://www.facebook.com/kashmirei.org/" class="wobble-hor-bottom circle1 d-flex justify-content-center align-items-center text-decoration-none"><i class="fab fa-facebook fs-1 color"></i></a>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2'
export default {
    data(){
        return{
            apiURL,
            moment,
            post:[],
            posts:[],
            images:[],
            form:{}
        }
    },
    methods:{
        fetchpost(){
            axios.get(`${apiURL}/posts/${this.$route.params.id}`).then(
                (data) => {
                    this.post = data.data
                }
            ).catch(() => {})
        },
        fetchimages(){
            axios.get(`${apiURL}/images?_sort=id:DESC&_limit=9`).then(
                (data) => {
                    this.images = data.data
                }
            ).catch(() => {})
        },
        fetchposts(){
            axios.get(`${apiURL}/posts?_sort=id:DESC&_limit=5`).then(
                (data) => {
                    this.posts = data.data
                }
            ).catch(() => {})
        },
        submitcomment(){
            axios.post(`${apiURL}/comments`,this.form).then(
                (data) => {
                    Swal.fire(
                        'Success',
                        'Your Comment Has Been Submitted',
                        'success'
                    )
                }
            ).catch(() => {
                Swal.fire(
                        'Error',
                        'All Fields Are Compulsory',
                        'error'
                    )
            })
        }
    },
    mounted() {
        this.fetchimages();
        this.fetchposts();
        this.fetchpost();
        Fire.$emit('navigate');
    }
}
</script>
<style scoped>
.ul-height{
        height: 100%;
    }
    .first-container-fluid{
        background-color: black;
    }
    .second-container-fluid{
        background: #4a4a4a;
    }
    .donate-now{
        background-color: #1baa79;
        font-size: 15px;
        font-weight: 500;
        border-radius: 0px;
    }
    .nav-icon{
        color: #1baa79;
    }
    .home-icon{
    color: #1baa79;
    }
    .charity-link{
        color: #1baa79;
        font-size: 18px;
        font-weight: 700;
    }
    .facebok-button{
        background-color: white;
        color:#1baa79;
        border: 1px solid #1baa79;
        border-radius: 0px;
    }
    .font-size1{
        font-size: 15px;
    }
    .qoute-icon{
        color:#1baa79;
        font-size: 28px;
    }
    .qoute-div{
        border-left: 8px solid #1baa79;
        border-top: 1px solid #1baa79;
        border-right: 1px solid #1baa79;
        border-bottom: 1px solid #1baa79;
        padding: 18px;
    }
    .girl-img{
        width: 200px;
        margin: 30px 30px 0px 0px;
    }
    .col-8{
        margin: 30px 0px 0px 0px;
        padding: 0px;
    }
    .img-text-row{
        border-top: 1px solid rgb(228, 224, 224);
    }
    .span-h2{
        font-weight: 900;
    }
    .h-row-4{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 100px;
        display: block;
        /* margin-left: auto; */
        /* margin-right: auto; */
        margin-top: 10px;
    }
    .h-row-5{
        color: #1baa79;
        opacity: 1;
        height: 3px;
        width: 70px;
        display: block;
        /* margin-left: auto; */
        /* margin-right: auto; */
        margin-top: 10px;
    }
    .circle{
        background-color: rgb(241, 235, 235);
        height: 100px;
        width: 100px;
        border-radius: 5rem;
    }
    .circle1{
        background-color:white;
        height: 60px;
        width: 60px;
        border-radius: 5rem;
        border:1px solid rgb(168, 166, 166)
    }
    .color{
        color:#1baa79;
    }
    .text-area{
        border: 1px solid rgb(209, 203, 204);;
    }
    .input-text{
        margin-top: 5px;
        border: 1px solid rgb(192, 188, 188);
        border-radius: 4px;
        width: 250px;
    }
    .chat{
        border-top: 1px solid rgb(209, 203, 204);
        border-right: 1px solid rgb(209, 203, 204);
        border-bottom: 5px solid #1baa79;
        border-left: 1px solid rgb(209, 203, 204);
        padding: 16px;
    }
    .font-size{
        font-size: 14px;
    }
    .leaf{
        width: 40px;
    }
    .p-footer{
        margin-top: 100px;
    }
    .div-img-footer{
        margin-top: 90px;
    }
    .div-footer{
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .footer{
        background: #4a4a4a;
    }
    .img{
        width: 250px;
    }
    .border{
        padding: 0px;
        border:none !important;
    }
    .border1{
        border-top: 1px solid rgb(177, 174, 174);
        border-right: 1px solid rgb(177, 174, 174);
        border-bottom: 5px solid #1baa79;
        border-left: 1px solid rgb(177, 174, 174);
        padding: 10px;
    }
    .w-35{
        width: 35%;
    }
    .w-60{
        width: 65%;
    }
    .side-box{
        padding-left: 60px;
        margin-top: 20px;

    }
    .w-30{
        width: 30%;
    }
</style>