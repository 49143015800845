<template>
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light d-md-none mobilepadding">
        <div class="container-fluid">
                <router-link to="/" class="d-flex align-items-center text-body text-decoration-none">
                    <img src="assets/images/logo_kei.png" width="40px" height="40px">
                    <div class="ms-2 tracking-in-expand">
                        <h1 class="fs-head fw-bolder lh-1 m-0">Kashmir Education</h1>
                        <h1 class="fs-head fw-light lh-1 m-0">Initiative</h1>
                    </div>
                </router-link>
                <button class="navbar-toggler"
                 type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span
                    class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item mt-3" @click="collapse()"><router-link  class="nav-link active" aria-current="page" to="/"><i class="fas fa-hockey-mask"></i>Home</router-link></li>
                    <li class="nav-item" @click="collapse()"><router-link class="nav-link" to="/programs">Programs</router-link></li>
                    <li class="nav-item" @click="collapse()"><router-link  class="nav-link" aria-current="page" to="/team">About</router-link></li>
                    <li class="nav-item" @click="collapse()"><router-link class="nav-link" to="/blog">Media</router-link></li>
                    <li class="nav-item" @click="collapse()"><router-link class="nav-link" to="/contact">Contact Us</router-link></li>
                    <li class="nav-item" @click="collapse()"><a class="nav-link" data-bs-toggle="modal" href="#Legal" role="button">Legal Compliance</a></li>
                    <li class="nav-item mt-2" @click="collapse()"><a class="btn btn-sm btn-success" data-bs-toggle="modal" href="#Donate1" role="button">Donate</a> <a class="btn btn-sm btn-success" data-bs-toggle="modal" href="#Volunteer" role="button">Become Volunteer</a></li>
                </ul>
                
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    methods:{
        collapse(){
            let node = document.querySelector('#navbarSupportedContent');
            node.classList.remove("show");
        }
    }
}
</script>
<style lang="css" scoped>
.nav-item{
    padding: 0px !important;
}
    .fixed-top{
        z-index:5555 !important;
    }
    .navbar-nav > .nav-item{
  padding: 0.5rem 0rem
}
</style>